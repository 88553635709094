export default {
    name: "logistics",
    components: {

    },
    data: () => {
        return {
            logisticsList: [
                {
                    time: "06-16-2022 12:52:59",
                    status: "Received",
                    location: "Signer: Kyire Lriving"
                }, {
                    time: "06-16-2022 12:52:59",
                    status: "Waiting for pickup",
                    location: "Pack"
                }, {
                    time: "06-16-2022 12:52:59",
                    status: "In transit",
                    location: "The pack has arrived in New York"
                }, {
                    time: "06-16-2022 12:52:59",
                    status: "In transit",
                    location: "The pack has arrived in New York"
                }, {
                    time: "06-16-2022 12:52:59",
                    status: "Shipped",
                    location: "The parcel has been sent from the warehouse"
                }, {
                    time: "06-16-2022 12:52:59",
                    status: "Order Preparing",
                    location: "Prepare the package"
                }
            ]
        }
    },
    watch: {
        '$route': 'fetchData',
    },
    created() {

    },
    mounted() {

    },
    computed: {

    },
    methods: {
        fetchData() {

        },
        backClick() {
            this.$router.go(-1);
        },
    }
}
