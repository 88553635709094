<template>
    <div class="logistics">
        <div class="logisticsMain">
            <div class="logistics-title">
                <div class="back" @click="backClick">
                    <img src="@/assets/images/personal/z.png" />
                    <span>Back</span>
                </div>
                <h5>Logistics</h5>
            </div>
            <div class="logistics-main">
                <div class="main-order">
                    <div>
                        <span>Tracking Number</span>
                        <p>454644579846541</p>
                    </div>
                    <div>
                        <span>Date</span>
                        <p>JAN 1, 2022</p>
                    </div>
                    <div>
                        <span>Order NO.</span>
                        <p>ASDFJ4579846541</p>
                    </div>
                </div>
                <div class="main-table">
                    <div class="table-header">
                        <div>
                            <span>Time</span>
                        </div>
                        <div>
                            <span>Parcel Status</span>
                        </div>
                        <div>
                            <span>Location</span>
                        </div>
                    </div>
                    <div class="table-body">
                        <div class="body-tr" v-for="(item, index) in logisticsList" :key="index">
                            <div class="body-time">
                                <div>
                                    <div class="line"></div>
                                    <span>{{item.time}}</span>
                                </div>
                            </div>
                            <div class="body-status">
                                <div>
                                    <span>{{item.status}}</span>
                                </div>
                            </div>
                            <div class="body-location">
                                <div>
                                    <span>{{item.location}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "./css/logistics.scss";
</style>

<script>
import logistics from "./js/logistics.js"

export default {
    name: "logistics",
    components: {},
    mixins: [logistics]
}
</script>